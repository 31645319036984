const floors = [
  'Perlentheater',
  'Workshop Zelt',
  '1000 Meter',
  'Kino',
  'Feuerjurte',
  'Walking Act'
].sort()

const floorDescriptions = {
  de: {
    Perlentheater: 'Unsere Live Bühne',
    'Workshop Zelt':
      'Hier finden Workshops statt',
    '1000 Meter': 'Hier gibts elektronische Musik',
    Kino: 'ein Kino',
    Feuerjurte: 'Workshops und anderes in der Feuerjurte',
    'Walking Act': 'keine Bühne, finde die Performance irgendwo auf dem Gelände'
  },
  en: {
     Perlentheater: 'Unsere Live Bühne',
    'Workshop Zelt':
      'Hier finden Workshops statt',
    '1000 Meter': 'Hier gibts elektronische Musik',
    Kino: 'ein Kino',
    Feuerjurte: 'Workshops und anderes in der Feuerjurte',
    'Walking Act': 'keine Bühne, finde die Performance irgendwo auf dem Gelände'
  },
}

const types = [
  'DJ',
  'Band',
  'Talk',
  'DIY-Angebot',
  'Performance',
  'Workshop',
  'Other',
]

const days = ['Fr', 'Sa', 'So']

module.exports = {
  floors,
  days,
  types,
  floorDescriptions,
}
