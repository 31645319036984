import { handleActions } from 'redux-actions'
import * as R from 'ramda'
import {
  SELECT_FILTER,
  OPEN_FILTER,
  CLOSE_FILTER,
  CHANGE_FILTER,
  CHECK_SAVED_FILTER_VALUES,
  RESET_FILTER,
} from './actions'
import { types, floors } from './consts'

const getOriginalState = () => ({
  floors: [],
  types: [],
  days: 'Fr',
  search: '',
  info: '',
  floorInfo: '',
  openedFilter: null,
})

export default handleActions(
  {
    [SELECT_FILTER]: (state, action) => {
      const { filterId, value } = action.payload

      console.log('###############', value)

      let filter = state[filterId]
      filter = value

      return {
        ...state,
        [filterId]: filter,
      }
    },

    [CHANGE_FILTER]: (state, action) => {
      const { filterId, value } = action.payload

      // console.log('###############', value)

      let filter = state[filterId]

      if (
        filterId === 'days' ||
        filterId === 'search' ||
        filterId === 'info' ||
        filterId === 'floorInfo'
      ) {
        filter = value
      } else {
        // remove value
        if (R.contains(value)(filter)) {
          filter = R.reject((filterValue) => filterValue === value)(filter)
        }
        // or add value
        else {
          filter = [...filter, value]
        }
      }

      return {
        ...state,
        [filterId]: filter,
      }
    },

    [RESET_FILTER]: (state, action) => {
      const filterId = action.payload
      let value = []

      console.log('############### RESET_FILTER filterId: ', filterId)
      if (filterId === 'search') {
        value = ''
      }

      return {
        ...state,
        [filterId]: value,
      }
    },

    [OPEN_FILTER]: (state, action) => {
      return {
        ...state,
        openedFilter: action.payload,
      }
    },

    [CLOSE_FILTER]: (state, action) => {
      return {
        ...state,
        openedFilter: null,
      }
    },
    [CHECK_SAVED_FILTER_VALUES]: (state) => {
      const savedFloors = R.filter((floor) => R.contains(floor, floors))(
        state.floors
      )
      const savedTypes = R.filter((type) => R.contains(type, types))(
        state.types
      )
      return {
        ...state,
        floors: savedFloors,
        types: savedTypes,
      }
    },
  },
  getOriginalState()
)
