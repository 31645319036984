import React, { FC, useEffect, useState } from 'react'
import {
  Page,
  Content,
  Message,
  SpecialContent,
  H5,
  Text,
  H3,
  Caption,
  Spacer,
  colors,
} from '../components'
import styled from 'styled-components/macro'
import { BottomAreaContainer } from '../BottomArea/BottomAreaContainer'
import { PageTitle } from '../components/PageTitle'
import { Config } from '../const'
import { useLang } from '../state/language'




const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const TitleCircleOuter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #848283;
  padding-bottom: 12px;
`

const InfosOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-top: 16px;
`

type Props = {
  // onClose: () => void
}

export const Departure: FC<Props> = (props) => {
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  const lang = useLang()

  return (
    <Overlay>
      <Page withBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_DEPARTURE" />} />
        <Content>
        <iframe src="https://grafana.saunahoch13.de/d/bdzv71fkzha0wb/splash24-app-website?orgId=1&refresh=30s" width="100%" height="800"></iframe>
        </Content>
        <BottomAreaContainer showFilterButton={false} />
      </Page>
    </Overlay>
  )
}
